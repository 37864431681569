import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  fetchData,
  formatDate,
  fetchCategoryName,
  fetchTagName,
  fetchPreviousPost,
  fetchNextPost,
} from "../utils/api";

const Post = () => {
  const location = useLocation();
  const pathname = location.pathname + location.search;
  const params = new URLSearchParams(pathname.split("post/")[1]);
  const id = params.get("id");
  // postCategory se obtiene desde la URL pero se puede ajustar según la lógica de la app
  const postCategory = params.get("category");

  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [previousPost, setPreviousPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);

  useEffect(() => {
    const getPost = async () => {
      const data = await fetchData(
        `https://blog.juanfalibene.com/wp-json/wp/v2/posts/${id}`
      );
      if (data) {
        setPost(data);

        // Recupera información completa de cada categoría para tener acceso al slug
        const categoryData = await Promise.all(
          data.categories.map((categoryId) => fetchCategoryName(categoryId))
        );
        setCategories(categoryData);

        // Recupera información de los tags
        const tagData = await Promise.all(
          data.tags.map((tagId) => fetchTagName(tagId))
        );
        setTags(tagData);

        // Utiliza funciones de api.js para obtener posts adyacentes
        const prevData = await fetchPreviousPost(data.date);
        const nextData = await fetchNextPost(data.date);

        // Solo asigna si el post adyacente existe y es distinto al actual
        if (prevData && prevData.length > 0 && prevData[0].id !== data.id) {
          const prevCatData = await fetchCategoryName(
            prevData[0].categories[0]
          );
          setPreviousPost({ ...prevData[0], catSlug: prevCatData.slug });
        }
        if (nextData && nextData.length > 0 && nextData[0].id !== data.id) {
          const nextCatData = await fetchCategoryName(
            nextData[0].categories[0]
          );
          setNextPost({ ...nextData[0], catSlug: nextCatData.slug });
        }
      }
    };
    getPost();
  }, [id]);

  if (!post) {
    return <p className='loading'>Cargando...</p>;
  }

  return (
    <article className='post-page'>
      <header className={`post-page-header ${postCategory}`}>
        <h1>{post.title.rendered}</h1>
      </header>
      <section className='post-page-body'>
        <aside className='post-page-sidebar'>
          <h2
            className='post-page-subtitle'
            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
          />
          <div className='post-page-tech'>
            <ul>
              <li className='date'>{formatDate(post.date)}</li>
              {categories.map((category, index) => (
                <li key={index}>{category.name}</li>
              ))}
            </ul>
            <ul>
              {tags.map((tag, index) => (
                <li key={index}>{tag.name}</li>
              ))}
            </ul>
          </div>
        </aside>
        <div className='post-page-content'>
          <div className='post-page-content-inner'>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </div>
          {/* Navegación entre posts */}
          <nav className='post-navigation'>
            {previousPost && previousPost.id !== post.id && (
              <Link
                className='nav-link previous'
                to={`/post/id=${previousPost.id}&category=${previousPost.catSlug}`}
              >
                &laquo; Anterior&nbsp;
              </Link>
            )}
            {nextPost && nextPost.id !== post.id && (
              <Link
                className='nav-link next'
                to={`/post/id=${nextPost.id}&category=${nextPost.catSlug}`}
              >
                Siguiente&nbsp;&raquo;
              </Link>
            )}
          </nav>
        </div>
      </section>
    </article>
  );
};

export default Post;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchData, formatDate, fetchCategoryName } from "../utils/api";
import Emoji from "./Emoji";

const Home = () => {
  // post via api.js
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPosts = async () => {
      const data = await fetchData(
        "https://blog.juanfalibene.com/wp-json/wp/v2/posts/?per_page=15"
      );
      if (data) {
        setPosts(data);

        const categoryCount = {};
        const categoryList = [];

        await Promise.all(
          data.map(async (post) => {
            if (post.categories.length > 0) {
              const category = await fetchCategoryName(post.categories[0]);
              const slug = category.slug;
              const name = category.name;
              const categoryId = post.categories[0];

              categoryList[post.id] = { name, slug, id: categoryId };

              if (categoryCount[slug]) {
                categoryCount[slug].count += 1;
              } else {
                categoryCount[slug] = {
                  name: name,
                  slug: slug,
                  count: 1,
                };
              }
            } else {
              categoryList[post.id] = {
                name: "Uncategorized",
                slug: "uncategorized",
              };
            }
          })
        );

        setCategories(categoryList);
        setCategoriesName(categoryCount);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    getPosts();
  }, []);

  // Ordenar las categorías por el contador de mayor a menor
  const sortedCategories = Object.entries(categoriesName).sort(
    (a, b) => b[1].count - a[1].count
  );

  // Manejar clic en una categoría
  const handleCategoryClick = (slug) => {
    setActiveCategory(slug);
  };

  return (
    <>
      <div className='hero-section'>
        <h2>
          Escribir sobre mi{" "}
          <Link to={`/post/id=522&category=idea-de-proyecto`}>historia</Link> y{" "}
          <Link to='/case-studies'>casos de estudio</Link>, el{" "}
          <Link to='/archive'>archivo web</Link>, el camino de{" "}
          <Link to='/challenges'>aprendizaje</Link>, desafíos en el trabajo
          cotidiano, ideas de proyectos y algunas reflexiones o simples notas
          sobre el desarrollo web ({<Emoji />})...
        </h2>
        <p>
          Comenzaré escribiendo sobre este proyecto de blog, un desarrollo de
          WordPress Headless (REST API) en combinación con React.js...
        </p>
      </div>

      <div className='home-container'>
        <div className='home-container-categories'>
          <ul className='categories-list-container'>
            <li
              key={"all"}
              className='all'
              onClick={() => handleCategoryClick(null)}
            >
              {loading ? "Cargando..." : "Todas"}
            </li>
            {Object.entries(categoriesName).map(([key, category]) => (
              <li
                key={category.slug}
                className={category.slug}
                onClick={() => handleCategoryClick(category.slug)}
              >
                {category.name} ({category.count})
              </li>
            ))}
          </ul>
        </div>
        <div className='home-container-post'>
          {posts
            .filter((post) =>
              activeCategory && categories[post.id]
                ? categories[post.id].slug === activeCategory
                : true
            )
            .map((post) => {
              const postCategory = categories[post.id]?.slug || "uncategorized";

              return (
                <article
                  className={`container-post ${postCategory}`}
                  key={post.id}
                >
                  <Link to={`/post/id=${post.id}&category=${postCategory}`}>
                    <span className='date'>{formatDate(post.date)}</span>
                    <h3 key={post.id}>{post.title.rendered}</h3>
                    <div
                      className='container-post-excerpt'
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt.rendered,
                      }}
                    ></div>
                    <span className={`read-more ${postCategory}`}>
                      Leer más
                    </span>
                  </Link>
                </article>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Home;
